<template>
    <div class="touristrdata">
        <Header class="hide" />
    <Headers class="dispy" />
        <div class="bar">
            <BannerImage rulename="scenicspot_banner" />
            <div class="w">
                <Breadcrumb :crumblist="crumbdata" class="hide" />
                <div class="deti">
                    <h3 class="title">{{ result.theTitle }}</h3>
                    <div class="biaoo">
                        <el-row>
                            <el-col :md="10">
                                 <p class="laiy" v-if="result.theSource ">来源: {{ result.theSource }}</p>
                                <p class="laiy" v-else>来源: 未知</p>
                            </el-col>
                            <el-col :md="5">
                                <p class="time">时间: {{ result.createTime }}</p>
                            </el-col>
                            <el-col :md="3">
                                <p class="browse">浏览量: {{ result.readCount }}</p>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="desc" v-html="result.theCont"></div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import Breadcrumb from "@/components/main/Breadcrumb";
import Header from "@/components/main/Header";
import Footer from "@/components/main/Footer";
import { getAction } from '@/api/manage'
import BannerImage from '@/components/main/BannerImage'
import Headers from "@/components/wap/Headers";
export default {
    components: {
        Header,
        Footer,
		Breadcrumb,
        BannerImage,
        Headers
	},
	created() {
		this.loadData()
	},
	data() {
		return {
			crumbdata: [
				{name: '景点与路线',url: ''},
				{name: '旅游路线',url: '/tourisdeta'},
			],
			result: {}
		}
	},
	methods: {
		loadData(){
			getAction('/way/tourWay/queryById',{
				id: this.$route.query.id
			}).then(res => {
				if(res.success){
					this.result = res.result
					this.crumbdata.push({
						name: res.result.theTitle
					})
				}
			})
		}
	},
};
</script>
<style lang="scss" scoped>
.touristrdata {
    .bars {
        width: 100%;
    }
    background: url(/img/white-bg.9771c1fc.png) no-repeat fixed top left;
}
.deti {
    padding: 20px 0 50px;
    .title {
        text-align: center;
        font-size: 24px;
        font-weight: normal;
        color: #333333;
    }
    .biaoo {
        margin-top: 46px;
        margin-bottom: 30px;
        width: 1134px;
        background-color: #6EB40021;
        margin-left: 15px;
        .laiy {
            margin-left: 308px;
        }
        p {
            font-size: 14px;
            color: #999999;
            text-align: center;
            padding-bottom: 19px;
            padding-top: 19px;
        }
    }
    .desc {
        width: 1093px;
		margin: 0 auto;
        color: #666666;
		text-align: justify;
		min-height: 500px;
		p{
			img{
				display: block;
				width: 90% !important;
				margin: 0 auto;
			}
		}
    }
}
</style>
